@import url(https://fonts.googleapis.com/css?family=Albert+Sans:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: "ClashGrotesk-Medium";
}
