@font-face {
  font-family: "ClashGrotesk-Extralight";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Extralight.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Extralight.woff")
      format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Extralight.ttf")
      format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Light";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Light.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Light.woff") format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Regular";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Regular.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Regular.woff") format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Medium";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Medium.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Medium.woff") format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Semibold";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Semibold.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Semibold.woff")
      format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Semibold.ttf")
      format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Bold";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Bold.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Bold.woff") format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
/**
  * This is a variable font
  * You can control variable axes as shown below:
  * font-variation-settings: wght 700.0;
  *
  * available axes:
  'wght' (range from 200.0 to 700.0
  */
@font-face {
  font-family: "ClashGrotesk-Variable";
  src: url("../assets/fonts/ClashGrotesk/ClashGrotesk-Variable.woff2")
      format("woff2"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Variable.woff")
      format("woff"),
    url("../assets/fonts/ClashGrotesk/ClashGrotesk-Variable.ttf")
      format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}
